import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { TextField } from '@mui/material';
import styles from '../../../../../index.module.scss';

interface ControlledNumberInputProps {
  name: string;
  control: Control<any>;
  label: string;
  disabled?: boolean;
  required?: boolean;
  minValue?: number; // default -90
  maxValue?: number; // default  90
  rules?: Record<string, any>;
}

const ControlledNumberInput: React.FC<ControlledNumberInputProps> = ({
  name,
  control,
  label,
  disabled = false,
  required = false,
  minValue = -90,
  maxValue = 90,
  rules,
}) => {
  const integerRegex = /^-?\d+$/;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        required: required ? 'This field is required' : false,
        validate: (value: string) => {
          if (!integerRegex.test(value)) {
            return 'Only integer values allowed';
          }
          const numericValue = Number(value);
          if (numericValue < minValue || numericValue > maxValue) {
            return `Value must be between ${minValue} and ${maxValue}`;
          }
          return true;
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type="number"
          label={label}
          disabled={disabled}
          required={required}
          inputProps={{
            min: minValue,
            max: maxValue,
            step: 1,
            style: {
              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
              borderRadius: '.5rem',
            },
          }}
          error={Boolean(error)}
          helperText={error ? error.message : ''}
          fullWidth
          sx={{
            fontSize: '1rem',
            margin: '0.5rem 0',
          }}
        />
      )}
    />
  );
};

export default ControlledNumberInput;
