import React from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@mui/material';
import styles from '../../../../../index.module.scss';

interface RadioOption {
  value: string;
  label: string;
}

interface ControlledRadioGroupProps {
  name: string;
  control: Control<any>;
  options: RadioOption[];
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  onChangeCallback?: (value: string) => void;
}

const ControlledRadioGroup: React.FC<ControlledRadioGroupProps> = ({
  name,
  control,
  options,
  defaultValue,
  required = false,
  disabled = false,
  onChangeCallback
}) => {
  return (
    <FormControl
      fullWidth
      disabled={disabled}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        rules={{
          required: required ? `${name} is required` : false
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup
              row
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                if (onChangeCallback) {
                  onChangeCallback(e.target.value);
                }
              }}
            >
              {options.map((opt) => (
                <FormControlLabel
                  key={opt.value}
                  value={opt.value}
                  label={opt.label}
                  control={
                    <Radio
                      sx={{
                        color: styles.mainGreen,
                        '&.Mui-checked': {
                          color: styles.mainGreen
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1rem'
                        }
                      }}
                    />
                  }
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '1rem'
                    },
                    marginRight: '1rem'
                  }}
                />
              ))}
            </RadioGroup>

            {error && (
              <FormHelperText sx={{ color: styles.mainRed }}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default ControlledRadioGroup;
