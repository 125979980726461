import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Box } from '@mui/material';
import styles from '../../../../../index.module.scss';

interface ControlledTextInputProps {
  name: string;
  control: any;
  label: string;
  disabled?: boolean;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  rules?: Record<string, any>;
}

const ControlledTextInput: React.FC<ControlledTextInputProps> = ({
  name,
  control,
  label,
  disabled,
  required,
  multiline,
  rows,
  maxLength,
  rules
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            fullWidth
            label={label}
            disabled={disabled}
            required={required}
            multiline={multiline}
            rows={rows}
            inputProps={{
              maxLength
            }}
            error={Boolean(error)}
            helperText={error?.message || ''}
            sx={{
              fontSize: '1rem',
              margin: '0.5rem 0',
              ...(multiline
                  ? {
                    textArea: {
                      border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                      borderRadius: '.5rem'
                    }
                  }
                  : {
                    input: {
                      border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                      borderRadius: '.5rem'
                    }
                  }
              )
            }}
          />
          {maxLength && (
            <Box
              sx={{
                textAlign: 'right',
                marginTop: '2px',
                marginRight: '0.2rem',
                fontSize: '0.7rem',
                color: styles.mainGrey
              }}
            >
              {field.value?.length || 0}/{maxLength}
            </Box>
          )}
        </>
      )}
    />
  );
};

export default ControlledTextInput;
