import { Box } from '@mui/material';
import styles from '../../../../../index.module.scss';
import React from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Control, Controller } from 'react-hook-form';
import { TimeField } from '@mui/x-date-pickers';
import { formatTimeOffset } from '../../../../shared/date-time.formater';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { JournalTemplate } from '../base-template-form';

interface CommonTimeFieldProps {
  name: 'postingTime' | 'startDate' | 'endDate';
  control: Control<JournalTemplate>;
  readOnly?: boolean;
  timezone?: string;
}

const CommonTimeField: React.FC<CommonTimeFieldProps> = ({
  name,
  control,
  readOnly = false,
  timezone
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
        <Controller
          name={name}
          control={control}
          rules={{ required: 'Posting Time is required' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TimeField
              disabled={readOnly}
              label="Time"
              value={value}
              onChange={onChange}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error ? error.message : '',
                  fullWidth: true,
                  required: true,
                  style: { background: 'white' },
                  sx: {
                    margin: '0.5rem 0',
                    '& .MuiInputBase-root': {
                      '&.MuiOutlinedInput-root': {
                        border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                        borderRadius: '.5rem',
                        paddingRight: '.8rem' // Увеличим padding для размещения иконки и таймзоны
                      }
                    }
                  }
                }
              }}
            />
          )}
        />
        <Box
          sx={{
            position: 'absolute',
            left: '6rem', // Позиционируем текст таймзоны справа от поля ввода
            display: 'flex',
            marginTop: '1rem',
            fontSize: '0.8rem',
            alignItems: 'center',
            height: '100%',
            color: 'rgba(0, 0, 0, 0.54)',
            pointerEvents: 'none' // Это позволит избежать конфликтов с кликами
          }}
        >
          {timezone && (
            <Box component="span" sx={{ marginRight: '0.5rem' }}>
              {formatTimeOffset(timezone)}
            </Box>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default CommonTimeField;
