import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styles from '../../../../../index.module.scss';
import { AccountQB } from '../../clients-list';

interface AccountAutocompleteProps {
  name: string;
  control: any;
  accountList: AccountQB[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  filterAP_AR?: boolean;
}

const AccountAutocomplete: React.FC<AccountAutocompleteProps> = ({
  name,
  control,
  accountList,
  label,
  required,
  disabled,
  filterAP_AR = true
}) => {
  const [open, setOpen] = useState(false);

  const filteredAccounts = filterAP_AR
    ? accountList?.filter(
      (account) =>
        account.AccountType !== 'Accounts Receivable' &&
        account.AccountType !== 'Accounts Payable'
    )
    : accountList;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(_, newValue) => field.onChange(newValue)}
          options={filteredAccounts || []}
          value={field.value || null}
          isOptionEqualToValue={(option, value) => option.Id === value?.Id}
          disabled={disabled || !filteredAccounts?.length}
          disableClearable
          getOptionLabel={(option: AccountQB) =>
            `${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`
          }
          sx={{
            fontSize: '1rem',
            margin: '0.5rem 0',
            border: `1px solid ${styles.lightGrey}`,
            borderRadius: '.5rem'
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={label}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowForwardIosIcon
                      sx={{ fontSize: '1rem', cursor: disabled ? 'default' : 'pointer' }}
                      onClick={() => {
                        if (!disabled) {
                          setOpen((prev) => !prev);
                        }
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.Id}>
              {`${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
            </li>
          )}
        />
      )}
    />
  );
};

export default AccountAutocomplete;
